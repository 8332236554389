import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Layout } from "../../components/layout"
import { Container } from "../../theme/helpers"
import { colors, mediaQuery } from "../../theme"
import { Link } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

const PostContainer = styled.div`
  margin-top: 100px;
  font-family: DM Sans;

  .gatsby-image-wrapper {
    height: 400px;
    width: 100%;
    border-radius: 8px;
  }
`

const Title = styled.h1`
  font-size: 60px;
  font-weight: 700;
  line-height: 69px;
  color: ${colors.gray900};
  position: relative;
  border-left: 15px solid transparent;
  box-shadow: -15px 0px 0px 0px ${colors.pink1};
  margin: 80px 0 80px 15px;

  ${mediaQuery("lg")} {
    font-size: 36px;
    line-height: 30px;
  }
  ${mediaQuery("sm")} {
    font-size: 26px;
    line-height: 30px;
  }
`
const Content = styled.div`
  margin-left: 24px;
  h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    color: black;
    margin: 32px 0 60px;
  }
  strong {
    font-weight: 700;
  }
  ul {
    li {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      color: black;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    color: black;
  }

  ${mediaQuery("lg")} {
    h2 {
      font-size: 32px;
      line-height: 36px;
    }
    ul {
      li {
        font-size: 14px;
        line-height: 30px;
      }
    }
    p {
      font-size: 14px;
      line-height: 30px;
    }
  }
`

const BlogPost = ({ data }) => {
  const {
    wpPost: { title, content, featuredImage },
    wpPost,
  } = data
  console.log(wpPost)

  return (
    <Layout isAlwaysBlue>
      <Seo post={wpPost} />
      <Container>
        <PostContainer>
          <GatsbyImage
            image={getImage(featuredImage.node.localFile)}
          ></GatsbyImage>
          <Title>{title}</Title>
          <Content dangerouslySetInnerHTML={{ __html: content }}></Content>
        </PostContainer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2048, quality: 90)
            }
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
export default BlogPost
